.tag {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--heading);
  font-weight: 600;
  background: var(--foreground);
  width: max-content;
  padding: 0.2rem;
}

.tag:hover {
  color: var(--highlight);
}