.projects {
  margin: 20px 0;
}


.projects__title {
  font-size: var(--fs-md);
  padding: 0 24px;
}

.projects_carousel {
  padding-left: 24px
}