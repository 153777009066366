.native_link {
  background: var(--primary);
  color: var(--heading);
  font-size: var(--fs-sm);
  font-weight: 500;
  padding: 1rem 1.25rem;
  border-radius: var(--border-radius);
  position: relative;
  z-index: 2;
  transition: 0.2s ease;
  outline: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: max-content
}


.native_link:hover,
.native_link:hover::after,
.native_link:focus,
.native_link:focus::after  {
  background: var(--primary-lighter);
}

.native_link-pulse::after,
.native_link-pulse::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.native_link-pulse::after {
  background-color: var(--primary);
  z-index: -2;
  border-radius: var(--border-radius);
}


.native_link-pulse::after {
  animation: pulse infinite 1.25s;
}

.native_link-pulse:hover::after,
.native_link-pulse:focus::after {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}