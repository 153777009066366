.home p {
  margin-bottom: 14px;
}
.home h2, 
.home h3, 
.home h4 {
  margin-bottom: 1rem;
}

.header {
  padding: 50px 34px;
  display: flex;
  align-items: center;
  justify-content: space-between; 
}

.header__name {
  font-size: var(--fs-md);
  font-weight: 700;
}

.header__avatar {
  border-radius: 50%;
}

.presentation {
  margin: 40px 0;
}

.presentation_title {
  font-size: var(--fs-lg);
  font-weight: 700;
  margin: 40px 0;
  padding: 24px;
}

.presentation__cta {
  padding: 24px;
  margin-bottom: 1rem;
}

.presentation__banner {
  width: 100%;
  height: 200px;
  position: relative;
}

.presentation__banner img {
  object-fit: cover;
  object-position: right;
}

.about {
  padding: 24px;
}

.about__title {
  font-size: var(--fs-md);
  font-weight: 700;
}

.about__subtitle {
  font-size: var(--fs-lg);
}

.social {
  padding: 24px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.social__icon {
  background-color: var(--heading);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 1024px) {
  .presentation_title,
  .about__subtitle {
    font-size: var(--fs-md);
  }
}