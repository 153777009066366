.contact {
  padding: 24px
}

.contact__title {
  font-size: var(--fs-md);
}
.contact__form {
  display: grid;
  gap: 0.5rem
}

.contact__cta-email {
  font-size: var(--fs-lg);
  font-weight: 500;
  color: var(--heading);
  margin-bottom: 1rem;
  display: inline-block;
}

@media (max-width: 762px) {
  .contact__cta-email {
    font-size: var(--fs-md);

  }
}
